import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import iconSet from "../images/icons-x6.svg";
import iconSlack from "../images/logo-slack.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const IndexPage = ({ data }) => {
  const newsItems = data.allContentfulNewsItem.edges;




  return (
    <Layout>
      <SEO title="Lawtech Hub Home" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
        <h1 className="title">{"Lawtech Hub"}</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-6 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 pr-0 md:pr-10 text-center sm:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={iconSet} className="h-64 mb-0 float-right mx-6" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>




      <div className="container mt-12">
        <h1>What's New?</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 -mx-2 mt-16">
          {newsItems.map(({ node: post }) => (


            <Link to={`/playbook/${post.slug}`} key={post.id} className="hover-card bg-white shadow-sm rounded-sm mb-2 group cursor-pointer">
              <Img
                className="object-cover h-48 rounded-t-sm"
                fluid={post.heroImage.fixed}
                alt="Header"
              />
              <div className="px-4 pt-4 pb-6">
                <div className="tags mb-4">
                  {post.tags.map(tag => (
                    <span className="text-xs text-purple-600 border border-purple-200 px-1" key={tag}>
                      {tag}
                    </span>
                  ))}
                </div>
                <h3 className="group-hover:text-purple-800">{post.title}</h3>
                <p className="text-sm pb-2">
                  {post.description.description}
                </p>
                <div className="absolute bottom-0 mb-4 text-xs font-serif italic text-gray-600">{post.publishDate}</div>
              </div>
            </Link>
          ))}
          <span className="" />
        </div>
      </div>





      <div className="">
        <div className="container pt-2">
          {/* <h1>COHORT 3 RESIDENTS</h1>...
          <div className="residents grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 -mx-2 mt-12 pb-32">
            {Residents.map(({ node: post }) => (
              <Link to={`/residents/${post.slug}`} key={post.id} className="bg-white shadow-sm border border-gray-100 bg-purple-100 rounded-sm flex justify-center self-stretch h-40">
                <div className="self-center">
                  <Img
                    className="logo block homepage"
                    fixed={post.logo.fixed}
                    title={post.companyName}
                    alt={post.companyName}

                  />
                </div>
              </Link>
            ))}
          </div> */}
        </div>
      </div>




          {/*
      <div className="py-8">
        <div class="container text-center">
          <div className="w-full flex justify-center mb-4">
            <img src={iconSlack} className="w-32 h-12 mb-3" alt="Slack Logo" />
          </div>
          <div className="font-extrabold text-2xl tracking-tighter">Join LawTech Hub Community on Slack</div>
          <div className="my-4 text-sm">Access restricted to residents, partners, mentors, and Lander & Rogers.</div>
          <a href="https://join.slack.com/t/lawtechhub/shared_invite/zt-gumnanwy-YYLpBWRg50n1gcDBVMY4zg" target="_blank" rel="noreferrer">
            <button className="mt-4 bg-purple-800 text-white rounded px-4 py-2 font-bold text-base sm:text-xs hover:bg-purple-900">Request Access</button></a>
        </div>
      </div>
        */}

    </Layout>
  );
};




export const query = graphql`
  query homeNewsAndHeader {
    contentfulPageIntro(page: { eq: "Home" }) {
        intro {
          json
        } 
    }
    allContentfulNewsItem(sort: {order: DESC, fields: publishDate}, limit: 3) {
      edges {
        node {
          id
          title
          slug
          tags
          publishDate(formatString: "D MMMM, YYYY")
          body {
            body
          }
          description {
            description
          }
          heroImage {
            fixed(width: 400) {
              base64
              width
              height
              src
              srcSet
            }
          }
          tags
        }
      }
    }
    allContentfulResident(sort: {fields: companyName, order: ASC}) {
      edges {
        node {
          id
          slug
          companyName
          pitch {
            pitch
          }
          logo {
            fixed(width: 140) {
              base64
              src
              srcSet
              width
              height
            }
          }
        }
      }
    }
    file(relativePath: { eq: "banner-home.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;



export default IndexPage;